<template>
  <iframe width="100%" height="800" src="https://msit.powerbi.com/reportEmbed?reportId=9fa44760-dbf0-47be-8c08-828c0ce4b479&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D" frameborder="0" allowFullScreen="true"></iframe>
</template>

<script>
export default {
  components: {
  }
}
</script>
